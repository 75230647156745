<template>
  <!-- published = 0
    deleted = 1
    draft = 2 -->
  <div style="margin-left: 20px; margin-right: 20px">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Новости"
    />
    <a-button
      type="primary"
      style="margin-bottom: 10px"
      @click="$router.push({ path: 'add/', name: 'novosti.add' })"
      >Добавить новость</a-button
    >
    <a-table
      style="background-color: white"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :row-key="(record) => record.id"
    >
      <template v-slot:action="text, record">
        <a-tooltip placement="top" title="Редактировать новость"
          ><a-button
            style="margin-right: 15px"
            @click="
              $router.push({
                path: 'edit/:id',
                name: 'novosti.edit',
                params: { id: record.id, newsId: record.id },
              })
            "
            ><a-icon type="edit" /></a-button
        ></a-tooltip>
        <a-tooltip placement="top" title="Удалить новость"
          ><a-button @click="deleteNews(text.id)"
            ><a-icon type="delete" /></a-button
        ></a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: "9%",
  },
  {
    title: "Заголовок",
    dataIndex: "name",
    width: "40%",
  },
  {
    title: "Содержание",
    dataIndex: "text",
    width: "40%",
    ellipsis: true,
  },
  {
    title: "",
    key: "action",
    width: 150,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  data() {
    return {
      name: {
        first: "sdf",
        last: "vxcv",
      },
      data: [],
      pagination: {},
      loading: false,
      columns,
      newsList: null,
      totalPages: null,
      hasNextPage: null,
      hasPrevPage: null,
      errorMessage: null,
      currentPage: 1,
      pageNumber: 1,
    };
  },
  created: function () {
    this.getNewsList(this.currentPage);
  },
  methods: {
    ...mapActions(["fetchAccessToken"]),
    deleteNews(news_id) {
      axios
        .delete(`/cp/news/${news_id}/`)
        .then((response) => {
          console.log(response);
          this.getNewsList(this.currentPage);
        })
        .then((error) => {
          console.log(error);
        });
    },
    getNewsList(pageNumber) {
      this.loading = true;
      axios
        .get("/cp/news/", {
          params: {
            page: pageNumber,
            search: "",
          },
        })
        .then((response) => {
          this.data = response.data.data;
          this.currentPage = response.data.meta.pagination.page;
          this.totalPages = response.data.meta.pagination.total;
          this.hasNextPage = response.data.meta.pagination.has_next;
          this.hasPrevPage = response.data.meta.pagination.has_prev;
          this.errorMessage = null;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          // this.errorMessage = error;
          this.data = null;
        });
    },
  },
};
</script>
